import React from 'react';
import Gallery from '@browniebroke/gatsby-image-gallery';

export default function PhotoGallery({ rawImages }) {
  const images = [];
  rawImages.edges[0].node.images.map(function (item) {
    images.push(item.image.asset);
  });
  return <Gallery images={images} />;
}
