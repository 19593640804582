import React from 'react';
import { graphql } from 'gatsby';
import Hero from '../components/hero';
import PhotoGallery from '../components/photoGallery';
import PortableText from '../components/portableText';
import Seo from '../components/seo';

export const query = graphql`
  query PortfolioPageQuery {
    gallery: allSanityPage(
      filter: { _id: { regex: "/(drafts.|)portfolio/" } }
    ) {
      edges {
        node {
          images {
            image {
              asset {
                thumb: fluid(maxWidth: 270, maxHeight: 270) {
                  ...GatsbySanityImageFluid
                }
                full: fluid(maxWidth: 1024) {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
        }
      }
    }
    page: sanityPage(_id: { regex: "/(drafts.|)portfolio/" }) {
      id
      title
      _rawBody
      heroImage {
        crop {
          _key
          _type
          top
          bottom
          left
          right
        }
        hotspot {
          _key
          _type
          x
          y
          height
          width
        }
        asset {
          _id
        }
      }
    }
  }
`;

export default function PortfolioPage(props) {
  const { data, errors } = props;
  const page = data && data.page;
  const galleryImages = data.gallery;

  // const images = props.data.gallery.allFile.edges.map(
  //   ({ node }) => node.childImageSharp
  // );
  return (
    <>
      <Seo title={page.title} />
      <main>
        <Hero hero={page.heroImage} title={page.title} />
        <div className="photo-gallery">
          <section>
            <PortableText blocks={page._rawBody || []} />
          </section>
          <PhotoGallery rawImages={galleryImages} />
        </div>
      </main>
    </>
  );
}
